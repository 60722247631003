import handlePromise from 'utils/handle-promise'

export async function registerNewErrorService({ payload }) {
  const url = '/api/v1/payments/error/'
  const data = await handlePromise(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  return data
}

export async function registerBillingDetails({ payload }) {
  const url = '/api/v1/payments/bot/'
  const data = await handlePromise(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      payload,
    }),
  })
  return data
}

export async function getCouponEnabledService() {
  const url = '/api/v1/coupons/enabled/'
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

export async function getPaymentConfigService({ course_slug, countryCode }) {
  let url
  if (course_slug) {
    url = `/api/v1/payments/config/?country_code=${countryCode}&course_slug=${course_slug}`
  } else {
    url = `/api/v1/payments/config/?country_code=${countryCode}`
  }
  const data = await handlePromise(url, {
    method: 'GET',
  })

  return data
}

export async function getCouponStatusService({
  code,
  courseSlug,
  countryCode,
}) {
  const url = `/api/v1/coupons/${code}/status/?course_slug=${courseSlug}&country_code=${countryCode}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

function parseAbstractApiResponse(data) {
  return {
    ...data,
    countryCode: data.country_code,
  }
}

const defaultCountry = {
  query: '189.203.45.99',
  status: 'success',
  continent: 'North America',
  continentCode: 'NA',
  country: 'Mexico',
  countryCode: 'MX',
  region: 'CMX',
  regionName: 'Mexico City',
  city: 'Mexico City',
  district: '',
  zip: '03020',
  lat: 19.4324,
  lon: -99.1229,
  timezone: 'America/Mexico_City',
  offset: -21600,
  currency: 'MXN',
  isp: 'Total Play Telecomunicaciones SA De CV',
  org: 'Total Play Telecomunicaciones SA De CV',
  as: 'AS17072 TOTAL PLAY TELECOMUNICACIONES SA DE CV',
  asname: 'TOTAL PLAY TELECOMUNICACIONES SA DE CV',
  mobile: true,
  proxy: false,
  hosting: false,
}

const fetcher = (url) => fetch(url).then((res) => res.json())
const abstractApiKey = 'c2629e3f3a65481280e2be86e6eb6928'
const ipApiKey = 'EpYM2JUElPKNPrj'
export async function getCountryService() {
  try {
    // const url = `https://pro.ip-api.com/json?key=${ipApiKey}`
    const url = 'http://ip-api.com/json?fields=61439'
    const data = await fetcher(url)
    return data
  } catch (err) {
    // If an extension block ip-api, we can use our server
    // to get the ip from the req object
    const data = await fetcher('/api/location')
    return data
  }
}

export async function getPaymentCountryService({ ip }) {
  const url = `http://ip-api.com/json/${ip}?fields=61439`

  const data = await fetcher(url)
  return data
  // IP API
}

// TODO: RES
export async function getPaymentStatusService({
  course_slug,
  token,
  res = null,
  req = null,
  email,
}) {
  const emailQuery = `?email=${email}`
  const url = `/api/v1/courses/${course_slug}/payment-status/${
    email ? emailQuery : ''
  }`
  const data = await handlePromise(
    url,
    { headers: { Authorization: token } },
    res,
    req,
  )
  return data
}

export async function getFinancialPlansService({ amount, currency_code }) {
  const url = `/api/v1/financial-plans/?amount=${amount}&currency_code=${currency_code}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}
